import Penguin1 from "../images/reference/1.png"
import Penguin2 from "../images/reference/2.png"
import Penguin3 from "../images/reference/3.png"
import Penguin4 from "../images/reference/4.png"
import Penguin5 from "../images/reference/5.png"

export const TIMELINE_SKINNERS = [
  {
    img: Penguin1,
    text: "Nastavení technického pozadí doručitelnosti",
  },
  {
    img: Penguin2,
    text: "Práce s databází",
  },
  {
    img: Penguin3,
    text: "Příprava Drag&Drop šablony",
  },
  {
    img: Penguin4,
    text: "Pravidelné rozesílky",
  },
  {
    img: Penguin5,
    text: "Automatizace",
  },
]
