import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Cubes, H2, PaddingWrapper } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/skinners/logo-black.png"
import Background from "../../../images/reference/skinners/background.png"
import Background2 from "../../../images/reference/skinners/background2.png"
import About from "../../../images/reference/skinners/about.png"
import Banner from "../../../images/reference/skinners/bottom-banner.png"
import Databaze from "../../../images/reference/skinners/databaze.jpg"
import Template from "../../../images/reference/skinners/template.gif"
import Foot from "../../../images/reference/skinners/wireframe1.png"
import Graf from "../../../images/reference/skinners/graf.svg"
import Arm from "../../../images/reference/skinners/arm.svg"
import TopBanner from "../../../images/reference/skinners/banner.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import PenguinsTimeline from "../../../components/reference-detail/penguins-timeline"
import { TIMELINE_SKINNERS } from "../../../model/reference-timeline-skinners"

class Skinners extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Reference na marketingové práce pro Skinners | igloonet</title>
          <meta
            name="description"
            content="Ve spolupráci jsme pravidelně komunikovali se zákazníky, fanoušky a backery, které Skinners nasbíraly na jednotlivých platformách."
          />
        </Helmet>

        <ReferenceHeader
          companyName="SKINNERS"
          heading="Nastavení e-mailové komunikace Skinners"
          date="leden 2018 - srpen 2019"
        />

        <ReferenceImageText
          right
          img={TopBanner}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo Skinners"
          link="//skinners.cc/cs/"
        >
          <h2>Co nás čekalo</h2>
          <p>
            Nastavení komunikace s backery a odběrateli Skinners hlavně z
            Kickstarteru a IndieGoGo.
          </p>
          <Cubes
            bgWhite
            data={[
              {
                description: "Open rate přes",
                number: "50 %",
              },
              {
                description: "Zvýšení CLV",
                image: Graf,
              },
              {
                description: "Vysoký engagement publika",
                image: Arm,
              },
            ]}
          />
        </ReferenceImageText>

        <PaddingWrapper>
          <ReferenceImageText
            halfImgSize
            img={Background}
            responsiveTextOrder
            alt=""
          >
            <h2>Představení</h2>
            <p>
              Brněnské ponožkoboty Skinners odstartovaly úspěšnou Kickstarter
              kampaní v roce 2016. Celkově vybraly 654 557 dolarů od 9 624
              backerů a zařadily se mezi nejúspěšnější české crowdfundingové
              projekty. Po úspěšné kampaní se celý projekt přesunul na platformu
              IndieGoGo, kde dodnes utržil přes 2,2 milionu dolarů.
            </p>
            <p>
              Skinners jsou v tuto chvíli k dostání přes širokou síť partnerů,
              IndieGoGo, Amazon nebo přímo přes e-shop. Zboží doručují do více
              než 100 zemí světa.
            </p>
            <p>
              Samotný produkt způsobil menší revoluci v barefoot obuvi. Skinners
              přináší volnost a skladnost ponožek při obdobné ochraně nohy,
              jakou nabízí pevná obuv, to vše v minimalistickém designu. Právě
              za design už získaly tři ceny: Red Dot Award, A’Design Award a
              Good Design Award.
            </p>
          </ReferenceImageText>
        </PaddingWrapper>

        <ReferenceImageText halfImgSize right img={Background2} alt="">
          <h2>Cíl spolupráce</h2>
          <p>
            Pravidelně komunikovat se zákazníky, fanoušky a backery, které
            Skinners nasbíraly na jednotlivých platformách. E-mail však nemá
            primárně fungovat jako prodejní kanál.
          </p>

          <PaddingWrapper>
            <h4>Hlavní cíle spolupráce</h4>
            <ul>
              <li>Zvyšování zapojení (engagementu) odběratelů</li>
              <li>Obsahový servis kolem značky a samotného produktu</li>
              <li>Práce s databází</li>
            </ul>
          </PaddingWrapper>
        </ReferenceImageText>

        <PenguinsTimeline data={TIMELINE_SKINNERS} />

        <PaddingWrapper>
          <ReferenceImageText
            halfImgSize
            img={About}
            responsiveTextOrder
            alt=""
          >
            <h2>Nastavení technického pozadí doručitelnost</h2>
            <p>
              Pro rozesílání e-mailových kampaní jsme vybrali Mailkit. A to
              hlavně pro jeho vysokou doručitelnost a práci s e-mailovými
              šablonami.
            </p>
            <p>
              Společně se Skinners jsme nastavili technické náležitosti pro
              maximální doručitelnost, konkrétně nezbytné SPF, DKIM a DMARC.
            </p>
            <p>
              Jelikož Mailkit nebyl jedinou platformou pro rozesílání e-mailů
              (e-shop, IndieGoGo, poštovní server, CRM), bylo potřeba vše
              implementovat tak, aby nedocházelo k zahazování či nedoručení
              některé z transakční nebo běžné e-mailové komunikace.
            </p>
          </ReferenceImageText>
        </PaddingWrapper>

        <PaddingWrapper>
          <img
            src={Banner}
            alt=""
            className="img-fluid mx-auto d-flex"
            loading="lazy"
          />
        </PaddingWrapper>

        <ReferenceImageText halfImgSize right img={Databaze} alt="">
          <h2>Práce s databází</h2>
          <p>
            S databází odběratelů jsme pracovali podle zdroje (platformy), přes
            níž se uživatel přihlásil k odběru. Již od začátku jsme komunikaci
            personalizovali a přistupovali ke každé platformě odlišným způsobem.
            Uživatele jsme dále segmentovali dle lokality, což nám mj. umožnilo
            cíleně rozesílat pozvánky na zajímavé eventy, kterých se Skinners
            účastní po celém světě.
          </p>
          <p>
            Další vrstvou segmentace pro nás byla RFM analýza, přinášející
            zajímavý pohled na odběratele-zákazníky. Vyhodnocovali jsme jejich
            celkové hodnoty a počty objednávek a období od posledního nákupu.
            Tím získal každý uživatel určité skóre, které jej přiřadilo k
            některému ze segmentů.
          </p>
          <p>
            Pokud se o RFM analýze chcete dozvědět více, přečtěte si náš článek{" "}
            <a href="https://igloonet.cz/blog/rfm-analyza-zakazniku/">
              RFM: Analýza zákazníků v kostce.
            </a>
          </p>
        </ReferenceImageText>

        <ReferenceImageText
          halfImgSize
          img={Template}
          responsiveTextOrder
          alt=""
        >
          <h2>Drag&Drop šablona</h2>
          <p>
            Obrovskou úsporou času při tvorbě obsahu pro nás byla Drag&Drop
            šablona. Díky ní jsme mohli nad jednotlivými částmi e-mailů
            přemýšlet jako nad moduly. Ty pak pro každý e-mail přeskládat dle
            informační struktury a typu zasílaného obsahu.
          </p>
          <p>
            Každý modul má obecně svůj vlastní kód a je tedy editovatelný
            zvlášť. To nám při práci dává možnost převést do Drag&Dropu
            jakýkoliv návrh grafika. Nenarážíme na hranice editoru jako u
            konkurenčních mailovacích nástrojů.
          </p>
        </ReferenceImageText>

        <ReferenceImageText halfImgSize right img={Foot} alt="">
          <h2>Výsledky</h2>
          <p>
            Databáze odběratelů reagovala díky segmentaci a personalizovanému
            přístupu k obsahu velice dobře. Kampaně měly dlouhodobý průměr
            otevření přes 50 %.
          </p>
          <p>
            E-mail měl i přes svůj neprodejní obsah velice pozitivní vliv na
            objednávky. U uživatelů se nám přes něj dařilo zvyšovat celkový
            počet nákupů na zákazníka, a tím i jeho hodnotu (CLV) pro e-shop.
          </p>
          <p>
            Engagement u klientů monitorujeme přes metriku Mailkitu, hodnotící
            uživatele od 1 do 5, kdy 5 je nejlepší. U Skinners jsme v průměru
            nad databází dosahovaly hodnoty engagementu 3,5. Postupně se nám
            dařilo hodnotu zvyšovat.
          </p>
        </ReferenceImageText>

        <ReferenceImageText>
          <H2>Co dál?</H2>
          <p>
            Se Skinners jsme pracovali na sérii automatických kampaní sloužících
            ke komunikaci před nákupem i po něm. Po posílení marketingového týmu
            klienta jsme e-mailing předali k interní správě. Podobně jako
            celosvětové kampaně na sociálních sítích a ve vyhledávání, které
            jsme od počátku nastavili a spravovali.
          </p>
        </ReferenceImageText>
        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Skinners
